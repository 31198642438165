import React from "react"
import Logo from "../Main/Logo"
import SectionDivider from "./../General/SectionDivider"

function ParallaxSection() {
    return (
        <section id="parallax" className="parallax-section">
            <SectionDivider SectionName="main" position="top"/>
            <Logo border=""/>
            <SectionDivider SectionName="main" position="bottom"/>
        </section>
    )
}

export default ParallaxSection