import React from "react"

function SectionContainer(props) {
    return (
        <div className="content-wrapper">
            {props.SectionContent}
        </div>
    )
}

export default SectionContainer