import React from "react"
import LeftSide from "./LeftSide"
import RightSide from "./RightSide"
import NavBar from "./NavBar"
import SectionDivider from "./../General/SectionDivider"

class Main extends React.Component {
    state = {
        isTop: true
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 1;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop })
            }
          });
    }

    render() {
        return (
            <section className="main section" id="home">
            <NavBar isTop={this.state.isTop ? 'top' : 'down'}/>
            <LeftSide />
            <RightSide />
            <SectionDivider SectionName="main" position="bottom"/>
            </section>
        )
    }
    
}

export default Main