import React from "react"
import SectionHeader from "../General/SectionHeader"
import SectionContainer from "../General/SectionContainer"
import SectionDivider from "../General/SectionDivider"

function Skills() {
    let SectionContent = 
    <div className="skills-table-wrapper">
        <table className="skills-table">
            <tr>
                <th></th>
                <th className="skill__header cell--inversed">Basic</th>
                <th className="skill__header">Elementary</th>
                <th className="skill__header cell--inversed">Intermediate</th>
                <th className="skill__header">Advanced</th>
            </tr>
            <tr>
                <th className="level__header">HTML</th>
                <td className="cell--inversed"></td>
                <td></td>
                <td className="cell--inversed"></td>
                <td className="cell--selected"></td>
            </tr>
            <tr>
                <th className="level__header">CSS</th>
                <td className="cell--inversed"></td>
                <td></td>
                <td className="cell--inversed"></td>
                <td className="cell--selected"></td>
            </tr>
            <tr>
                <th className="level__header">Javascript</th>
                <td className="cell--inversed"></td>
                <td></td>
                <td className="cell--inversed cell--selected"></td>
                <td></td>
            </tr>
            <tr>
                <th className="level__header">React</th>
                <td className="cell--inversed"></td>
                <td className="cell--selected"></td>
                <td className="cell--inversed"></td>
                <td></td>
            </tr>
        </table>
    </div>
    ;
    return(
        <section className="Skills" id="skills">
          <SectionDivider SectionName="main" position="top"/>
          <SectionHeader name="Skills" inversed="-inversed"/>
          <SectionContainer SectionContent={SectionContent}/>
          <SectionDivider SectionName="main" position="bottom"/>
        </section>
    )
}

export default Skills