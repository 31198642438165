import React from "react"

function NavBar(props) {
    return (
        <div className={"navbar-" + props.isTop} id="navbar">
            <a href="#home" className="navbar__link">Home</a>
            <a href="#about" className="navbar__link">About</a>
            <a href="#services" className="navbar__link">Services</a>
            <a href="#skills" className="navbar__link">Skills</a>
            <a href="#contact" className="navbar__link">Contact</a>
        </div>
    )
}

export default NavBar