import React from "react"

function ServiceItem(props) {
        if (props.side == "left") {
            return (
            <div className="service-item-wrapper">
              <div className={"service-item-svg service-item-svg-" + props.number}></div>
              <div className={"service-item-desc-" + props.side}>
                <h3 className="service-item-header">{props.header}</h3>
                <p className="service-item-text">{props.text}</p>
              </div>
            </div>
            );
        };
        if (props.side == "right") {
            return (
            <div className="service-item-wrapper">
              <div className={"service-item-desc-" + props.side}>
                <h3 className="service-item-header">{props.header}</h3>
                <p className="service-item-text">{props.text}</p>
              </div>
              <div className={"service-item-svg service-item-svg-" + props.number}></div>
            </div>
            );
        }
   

}

export default ServiceItem