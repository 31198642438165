import React from "react"
import Logo from "./Logo"

function LeftSide() {
    return (
        <div className="leftside">
        <Logo border="__border"/>
        </div>
    )
}

export default LeftSide