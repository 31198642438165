import React from "react"
// import { ProcessBubbleType } from "react-particles-js"

function Logo(props) {
    return (
        <div className={"logo-container" + props.border}>
            <h1 className="logo__name">Aivengo stream</h1>
            <div className="logo__divider"></div>
            <div className="logo__slogan">
                Design. Develop. Enjoy.
            </div>
        </div>
    )
}

export default Logo