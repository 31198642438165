import React from "react"

function SectionHeader(props) {
    return (
        <div className="header__wrapper">
            <h1 className={"header__text" + props.inversed}>{props.name}</h1>
            <div className="header__divider"></div>
        </div>
    )
}

export default SectionHeader