import React from "react"
import SectionHeader from "../General/SectionHeader"
import SectionContainer from "../General/SectionContainer"
import Typed from 'typed.js'

class About extends React.Component {
    componentDidMount() {
        const strings = ["Front-end developer","22 years old","Illustrator","UI/UX Designer","just a good guy"];
        // You can pass other options here, such as typing speed, back speed, etc.
        const options = {
            strings: strings,
            typeSpeed: 50,
            backSpeed: 50,
            backDelay: 2000,
            smartBackspace: true,
            loop: true
        };
        // this.el refers to the <span> in the render() method
        this.typed = new Typed(this.el, options);
    }
    
    render() {
        let SectionContent = 
        <p className="typing-text">
            I am <span className="typing" ref={(el) => { this.el = el; }}></span>
        </p>;
        return (
            <section className="About" id="about">
                <SectionHeader name="About" inversed=""/>
                <SectionContainer SectionContent={SectionContent}/>
            </section>
        )
    }
}

export default About