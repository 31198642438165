import React from "react"

function Footer() {
    return (
        <footer className="footer">
            <p className="footer__text">
                We can start working together right now
            </p><br/>
            <p className="footer__text">
                © 2020
            </p>
        </footer>
    )
  
}

export default Footer