import React from "react"
import AnimatedBackground from "./AnimatedBackground"

function RightSide() {
    return (
        <div className="rightside">
            <AnimatedBackground />
        </div>
    )
}

export default RightSide