import React from "react"

function SectionDivider(props) {
    return (
        // eslint-disable-next-line no-useless-concat
        <div className={props.SectionName + "_divider" + " " + props.position + "-" + "section__divider"}>
        </div>
    )
}

export default SectionDivider