import React from "react"
import Main from "./components/Main/Main"
import About from "./components/About/About"
import ParallaxSection from "./components/ParallaxSection/ParallaxSection"
import Services from "./components/Services/Services"
import Skills from "./components/Skills/Skills"
import Contact from "./components/Contact/Contact"
import Footer from "./components/Footer/Footer"
import { Lines } from 'react-preloaders';

function App() {
    return (
        <div className="section-container">
            <Main />
            <About />
            <ParallaxSection />
            <Services />
            <Skills />
            <Contact />
            <Footer />
            <Lines color="#7A63FF " background="#1E1E1E" time={1800} animation="slide"/>
        </div>
    )
}

export default App