import React from "react"
import SectionHeader from "../General/SectionHeader"
import SectionContainer from "../General/SectionContainer"
import SectionItem from "../Services/ServiceItem"

function Services() {
    return(
        <section className="Services" id="services">
          <SectionHeader name="Services" inversed=""/>
          <div className="content-wrapper">
            <SectionItem number="1" side="left" header="Prototype" text="About 9 million people in the UK, often or always feel lonely. In 2018 Theresa may appointed a minister of loneliness to tackle the loneliness epidemic.This fictional music campaign for the song 'None of my friends' by Liz Lawrence." />
            <SectionItem number="2" side="right" header="Design" text="About 9 million people in the UK, often or always feel lonely. In 2018 Theresa may appointed a minister of loneliness to tackle the loneliness epidemic.This fictional music campaign for the song 'None of my friends' by Liz Lawrence." />
            <SectionItem number="3" side="left" header="Develop" text="About 9 million people in the UK, often or always feel lonely. In 2018 Theresa may appointed a minister of loneliness to tackle the loneliness epidemic.This fictional music campaign for the song 'None of my friends' by Liz Lawrence." />
            <SectionItem number="4" side="right" header="Launch" text="About 9 million people in the UK, often or always feel lonely. In 2018 Theresa may appointed a minister of loneliness to tackle the loneliness epidemic.This fictional music campaign for the song 'None of my friends' by Liz Lawrence." />
          </div>
        </section>
    )
}

export default Services