import React from "react"
import SectionHeader from "../General/SectionHeader"
import SectionContainer from "../General/SectionContainer"
import Link from "./ContactLink"
// import $ from "jquery"

class Contact extends React.Component {
    componentDidMount() {
		// $('#contact-form').on("submit", function(e) {
		// 	alert("asd");
		// 	e.preventDefault();
	
		// 	var c_name = $('#c_name').val();
		// 	var c_email = $('#c_email').val();
		// 	var c_message = $('#c_message ').val();
		// 	var response = $('#contact-form .ajax-response');
			
		// 	var formData = {
		// 		'name'       : c_name,
		// 		'email'      : c_email,
		// 		'message'    : c_message
		// 	};
	
		// 	if (( c_name=== '' || c_email === '' || c_message === '')) {
		// 		response.fadeIn(500);
		// 		response.html('<i class="fa fa-warning"></i> Please fix the errors and try again.');
		// 	}
	
		// 	else {
		// 			 $.ajax({
		// 					type        : 'POST', // define the type of HTTP verb we want to use (POST for our form)
		// 					url         : 'php/contact.php', // the url where we want to POST
		// 					data        : formData, // our data object
		// 					dataType    : 'json', // what type of data do we expect back from the server
		// 					encode      : true,
		// 					success		: function(res){
		// 									var ret = $.parseJSON(JSON.stringify(res));
		// 									response.html(ret.message).fadeIn(500);
		// 					}
		// 				});
		// 		}           
		// 		return false;
		// 	});
	}
	
	render(){
		let SectionContent = 
		<div className="contact__wrapper">
			{/* <form id="contact-form">
				<div className="ajax-hidden">
					<div className="form-group wow fadeInUp">
						<label className="sr-only" for="c_name">Name</label>
						<input type="text" id="c_name" class="form-control" name="c_name" placeholder="Name"/>
					</div>

					<div className="form-group wow fadeInUp" data-wow-delay=".1s">
						<label className="sr-only" for="c_email">Email</label>
						<input type="email" id="c_email" class="form-control" name="c_email" placeholder="E-mail"/>
					</div>

					<div className="form-group wow fadeInUp" data-wow-delay=".2s">
						<textarea className="form-control" id="c_message" name="c_message" rows="7" placeholder="Message"></textarea>
					</div>

					<button type="submit" className="btn btn-lg btn-block wow fadeInUp" data-wow-delay=".3s">Send Message</button>
				</div>
				<div class="ajax-response"></div>
			</form> */}
			<Link contactLink="mailto:work@aivengo.stream" source="mail"></Link>
            <Link contactLink="https://t.me/aivengostream" source="telegram"></Link>
            <Link contactLink="https://github.com/TimGal" source="github"></Link>
		</div>
		;

    return(
        <section className="Contact" id="contact">
          <SectionHeader name="Contact" inversed=""/>
          <SectionContainer SectionContent={SectionContent}/>
        </section>
    )};
}

export default Contact